import React, { useEffect } from "react"
import "../styles/coveragepage.css"
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser"
import { Link } from "gatsby"
import SwipeToCall from "../components/SwipeToCall"

export default function CoveragePageHero(props) {
  useEffect(() => {
    var bg = document.getElementById("hero_bg")
    bg.style.backgroundImage = `url(${props.heroImage})`
    bg.style.backgroundSize = "cover"
  })
  return (
    <div id="hero">
      <div className="hero-text">
        <div id="hero_headline">
          <h1>{ReactHtmlParser(props.heroHeadline)}</h1>
        </div>
        <div id="hero_subhead">
          <h3>{props.heroSubhead}</h3>
        </div>
        <Link to="/">
          <button className="request-quote">Request a Quote</button>
        </Link>
      </div>
      <SwipeToCall></SwipeToCall>
    </div>
  )
}
