import React from "react"
import launchpadgraphic from "../img/launchpad-graphic-v2.png"

export default function Differentiator(props) {
  return (
    <div id="differentiator">
      <div id="heading">
        <h2>The Launchpad Quote System</h2>
      </div>
      <div className="card-container">
        <div className="card">
          <h3>
            Better
            <br />
            Technology
          </h3>
          <h5>
            321 LaunchPad is changing how businesses discover and qualify
            insurers, connecting thousands of businesses to one of hundreds of
            agents &amp; carriers
          </h5>
        </div>
        <div className="card">
          <h3>
            Better
            <br />
            Process
          </h3>
          <h5>
            Our entire business was built to save time and reduce uncertainty in
            the commercial insurance application process
          </h5>
        </div>
        <div className="card">
          <h3>
            Better
            <br />
            Coverage
          </h3>
          <h5>
            Our commercial insurance specialists educate customers on coverage
            options that are a proven fit for the business
          </h5>
        </div>
      </div>
      <div id="launchpad_graphic">
        <img src={launchpadgraphic} alt="insurance321 launchpad quote system" />
      </div>
      <div className="diff-copy">
        <p>
          Something many businesses don't know is that commercial insurance
          carriers have preferences for which types of businesses they insure.
        </p>
        <p>
          The problem for most business owners is that they don't know which
          carrier is the best fit for their business, leading to high premiums
          or declined applications for coverage .
        </p>
        <p>
          The 321 LaunchPad is connected to the largest network of commercial
          insurers available anywhere. We match your business with a qualified
          insurer so you get the right coverage for a fair price.
        </p>
      </div>
    </div>
  )
}
