import React, { useEffect } from 'react';
import  "../styles/click-to-call.css"
import phoneIcon from "../img/phone-icon.svg"
import phoneIconGreen from "../img/phone-icon-green.svg"
import closeX from "../img/close-x-dark.svg"
const SwipeToCall = () => {
    return (
        <div id="swipe_to_call">
            <input type="checkbox" id="toggle_overlay" unchecked />
            <label for="toggle_overlay">
            <div id="close_overlay"><img src={closeX} height="25px" width="25px" /></div>
                <div className="circle">
                    <div className="circle">
                    <div id="phone_icon"><img src= {phoneIcon} height="50px" width="50px" /></div>
                        <div className="click-to-call-overlay">
                            <h4>Need a faster quote?</h4>
                            <h4>Call one of our agents now!</h4>
                            <a href="tel:+1-234-230-6321"  id="call_button">
                                <button id="call_button_inner" className="request-quote call-conversion" >Call Now<img id="call_button_icon" src= {phoneIconGreen} height="30px" width="30px" color="#3ad574"/></button>
                            </a> 
                        </div>
                    </div>
                </div>
            </label>
        </div>
      );
}
 
export default SwipeToCall;