import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { fa, faMobile, faPhone } from "@fortawesome/free-solid-svg-icons"

export default function Service(props) {
  return (
    <div id="service">
      <div id="service_copy">
        <h4>
          Submit a quote request and we'll get in touch, or give us a call at:
          <br />
          <span className="phone-number call-conversion">
            <a href="tel:+1-234-230-6321">(234) 230-6321</a>
          </span>
          &nbsp;
          <span className="green">
            <FontAwesomeIcon icon={faPhone} />
          </span>
        </h4>
      </div>
      <div className="circle-container">
        <div className="circle" id="first_circle">
          <div className="circle">
            <div className="circle">
              <div className="circle">
                <div className="circle">
                  <Link to="/">
                    <button className="request-quote">
                      Request a Quote&nbsp; &rarr;
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
