import React from 'react';
import Differentiator from '../components/Differentiator';
import Footer from '../components/Footer';
import Header from '../components/Header';
import CoveragePageHero from '../components/CoveragePageHero';
import Service from '../components/Service';
import  "../styles/321global.css"
import  "../styles/professionpage.css"
import RecommendedCoverages from '../components/RecommendedCoverages';

const ProfessionPage = () => {

    return (
        <div id="profession_page">
            <Header />
            <CoveragePageHero heroHeadline="Business Insurance for Building Contractors" heroSubhead="Recommended coverages for your profession and industry" />
            <RecommendedCoverages />
            <Differentiator />
            <Service />
            <Footer />
        </div>


     );
}

export default ProfessionPage;
