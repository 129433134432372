import React, {useLayoutEffect} from 'react';
import { Link } from "gatsby"
import '../styles/icon-halo.css'
import target_icon from '../img/target-icon.svg'

export default function RecommendedCoverages (props) {
    useLayoutEffect(() => {
        let cards = document.querySelectorAll(".card")
        cards.forEach(card => {
            if (card.outerHTML.indexOf('<h4></h4>') > -1) {
                card.style.display = 'none'
            }
        })
    })
    
    return (
        <div id="recommended_coverages">
            <h2>Recommended Coverages</h2>
            <div className="reco-container">
                <div className="card"><Link to={"/"+props.reco_one_link}><div className ="profession-icon"><img src={target_icon} /></div><h4>{props.reco_one}</h4><p>{props.reco_one_blurb}</p></Link></div>
                <div className="card"><Link to={"/"+props.reco_two_link}><div className ="profession-icon"><img src={target_icon} /></div><h4>{props.reco_two}</h4><p>{props.reco_two_blurb}</p></Link></div>
                <div className="card"><Link to={"/"+props.reco_three_link}><div className ="profession-icon"><img src={target_icon} /></div><h4>{props.reco_three}</h4><p>{props.reco_three_blurb}</p></Link></div>
                <div className="card"><Link to={"/"+props.reco_four_link}><div className ="profession-icon"><img src={target_icon} /></div><h4>{props.reco_four}</h4><p>{props.reco_four_blurb}</p></Link></div>
                <div className="card"><Link to={"/"+props.reco_five_link}><div className ="profession-icon"><img src={target_icon} /></div><h4>{props.reco_five}</h4><p>{props.reco_five_blurb}</p></Link></div>
                <div className="card"><Link to={"/"+props.reco_six_link}><div className ="profession-icon"><img src={target_icon} /></div><h4>{props.reco_six}</h4><p>{props.reco_six_blurb}</p></Link></div>
            </div>
            {/* <div className="text-block">
                <p>Selecting the right types and levels of coverage for your business helps protect what you've worked hard to build.</p>
                <p>Once we receive your quote request, we'll give you a call to review your information and suggest any additional coverage you may want to consider based on your business details.</p>
            </div> */}
        </div>
        
    )
}